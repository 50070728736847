import { Container } from 'swp-components/lib/components/bootstrap'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import style from '@/components/news-events/homepage.style'
import { MainNewsGrid } from '@/components/news-grid'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const CatNewsMain = ({ serverData }) => {
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    allNewsList,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let arr = []
  let allArr = []
  let slug = serverData.slug
  if (!slug || !slug.cat || slug.cat === '') {
    return <NotFoundPage />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { handleMessage } = useNewsTipsDataLayer()
  if (allNewsList.length > 0) {
    // eslint-disable-next-line array-callback-return
    allNewsList.map((a, index) => {
      a.link = {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/news/${slug.cat}/${
          a.slug
        }`,
        newTab: true,
      }
      a.startDate = a.updatedAt
      if (a.isHighlight === true) {
        arr.push(a)
      }
    })
    // eslint-disable-next-line array-callback-return
    allNewsList.map(a => {
      if (a.isHighlight === false) {
        arr.push(a)
      }
    })

    allArr = arr
    // First time get limit 7 items
    arr = arr.slice(0, 7)
  }
  let customSeo = null
  if (slug.cat.toLowerCase() === 'trueonline') {
    customSeo = {
      metaDescription:
        'ไม่พลาดทุกข่าวสารประชาสัมพันธ์ ติดตามข่าวกิจกรรมและโปรโมชั่นดี ๆ จาก ทรูออนไลน์ได้ที่นี่',
      title: 'ติดตามทุกข่าวสารประชาสัมพันธ์จากทรูออนไลน์ได้ที่นี่ | TrueOnline',
    }
  } else if (slug.cat.toLowerCase() === 'truemoveh') {
    customSeo = {
      metaDescription:
        'ติดตามข่าวสาร โปรโมชั่นต่าง ๆ และร่วมสนุกกับกิจกรรม ลุ้นรางวัลมากมายจากทรูมูฟ เอช เครือข่ายอัจฉริยะอันดับหนึ่งในเอเชีย-แปซิฟิก',
      title: 'รวมข่าวสารโปรโมชั่นและกิจกรรมลุ้นรางวัลจากทรูมูฟ เอช |TrueMove H',
    }
  } else {
    customSeo = seo.mainSeo
  }

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={customSeo} script={headerScript.script} />
        <Header items={brandHeaders} services={productServices} />

        {arr.length > 0 && (
          <>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'News'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'ข่าวสารประชาสัมพันธ์'}
              </SectionHeading>
            </div>
            <Container className="mt-3 mb-5">
              <div css={style.box}>
                <MainNewsGrid
                  article={arr}
                  locale={serverData.locale}
                  path={slug.cat}
                  count={allArr}
                ></MainNewsGrid>
              </div>
            </Container>
          </>
        )}
        {arr.length === 0 && (
          <>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'News'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'ข่าวสารประชาสัมพันธ์'}
              </SectionHeading>
            </div>
            <Container className="mt-3 mb-5">
              <h2 css={style.header} className="my-5 py-5">
                {serverData.locale === 'th' ? 'ไม่พบข้อมูล' : 'No contents'}
              </h2>
            </Container>
          </>
        )}

        <Footer className="pb-4" items={footerHeaders} />
        <ChatShop
          content={chatShop}
          mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
          mariConfig={mariConfig}
        />
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default CatNewsMain

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents([
    `seo?_locale=${pageContext.locale}`,
    `header-script?_locale=${pageContext.locale}`,
    `brand-headers?_locale=${pageContext.locale}`,
    `product-services?_locale=${pageContext.locale}`,
    `footer-headers?_locale=${pageContext.locale}`,
    `chat-and-shop?_locale=${pageContext.locale}`,
    `news-and-events/?page_group.slug=${params.cat}&_locale=${pageContext.locale}`,
    'mari-chat-and-shop-config',
  ])

  let slug = params
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
